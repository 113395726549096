<template>
<div>
    <span style="width: 100%;text-align: center"> </span>

    <v-card>
        <v-card-title class="pa-4 info">
            <span class="title white--text">LISTE DES COLLECTES DES DONNEES AU NIVEAU DES PLUVIOMETRES</span>
        </v-card-title>

        <v-card-text>

            <v-container>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select :items="listdepartement" @change="fuseSearchdepartement" outlined hide-details item-value="id" v-model="selectdepartement" label="Departement *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="listcommune" @change="fuseSearchcommune" outlined hide-details item-value="id" v-model="selectcommune" label="Commune *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="listarrondissement" @change="fuseSearcharrondissement" outlined hide-details item-value="id" v-model="selectarrondissement" label="Arrondissement *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>

                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-select :items="listvillage" outlined hide-details item-value="id" v-model="selectvillage" label="Village/Ville *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>

                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">

                        <v-row>

                            <v-col cols="12" sm="6">
                                <v-select :items="listannee" outlined hide-details v-model="selectannee" label="Annee *">

                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="listtrimestre" outlined hide-details v-model="selecttrimestre" @change="fuseSearchtrimestre" label="Trimestre *">

                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="listmois" outlined hide-details v-model="selectmois" label="Mois *">

                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-select :items="list" outlined hide-details item-value="id" v-model="selectdirection" label="List des directions *">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle_atda }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle_atda }}
                                    </template>
                                </v-select>
                            </v-col>

                        </v-row>
                    </v-col>

                </v-row>

            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" dark @click="dialog=false">Annuler</v-btn>
            <v-btn color="success" dark @click="recherche">Recherche</v-btn>
        </v-card-actions>
    </v-card>

    <v-container fluid class="down-top-padding">
        <v-card>
            <v-card-text class="pa-2">

                <v-data-table :headers="headers" :items="list" sort-by="id" class="contact-listing-app">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Liste des collectes des donnees au niveau des pluviometres</v-toolbar-title>

                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" max-width="700">
                                <v-card>
                                    <v-card-title class="pa-4 info">
                                        <span class="title white--text">Formulaire</span>
                                    </v-card-title>

                                    <v-card-text>

                                        <v-container>
                                            <v-row>

                                                <v-col cols="12" sm="6">
                                                    <v-select :items="liststatus" @change="fuseSearch_status" outlined hide-details  v-model="selectstatus" label="Liste des status *">
                                                        
                                                    </v-select>
                                                </v-col>

                                                <v-col cols="12" sm="6">
                                                    <v-textarea outlined hide-details v-model="editedItem.observation" label="Observation *"></v-textarea>
                                                </v-col>

                                            </v-row>

                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" dark @click="dialog=false">Cancel</v-btn>
                                        <v-btn color="success" dark @click="save_status">Enregistrer</v-btn>
                                    </v-card-actions>
                                </v-card>

                            </v-dialog>
                        </v-toolbar>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex">

                            <v-icon large class="mb-2 mr-2 info--text" @click="getitem(item)">mdi-pencil</v-icon>
                            <v-icon large class="mb-2 mr-2 error--text" @click="deletItem(item)">mdi-delete</v-icon>
                        </div>
                    </template>

                    <template v-slot:no-data>

                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-btn v-if="item.status==1" color="success" dark class="mb-2 text-capitalize" @click="changeStatus(item)">
                            Valider</v-btn>
                        <v-btn v-else-if="item.status==2" color="error" dark class="mb-2 text-capitalize" @click="changeStatus(item)">
                            Rejeter</v-btn>
                        <v-btn v-else color="orange" dark class="mb-2 text-capitalize" @click="changeStatus(item)">
                            En attente</v-btn>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        <div>
                            <span>Date created : {{ item.created_at | formatDate }}</span>
                            <br>
                            <span>Date update: {{ item.updated_at | formatDate }}</span>
                        </div>
                        
                    </template>
                    <template v-slot:[`item.agent_releveur.nom`]="{ item }">
                        <span>{{ item.agent_releveur.nom   }}</span>
                        <span>{{ item.agent_releveur.prenom   }}</span>
                    </template>

                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "ProjectList",
    dialog: false,
    props: {
        source: String
    },
    data: () => ({
        dialog: false,
        search: "",
        headers: [

            {
                text: "Validation",
                value: "status",
                divider: true,
                width: '50px'
            },
            {
                text: "Id",
                align: "start",
                sortable: false,
                value: "id",
                divider: true,
                width: '5%'
            },
            {
                text: "Date",
                align: "start",
                sortable: false,
                value: "created_at",
                divider: true,
                width: '200px'
            },
            {
                text: "Agent releveur",
                align: "start",
                sortable: false,
                value: "agent_releveur.nom",
                divider: true,
                width: '5%'
            },
            {
                text: "Station Pluviometre",
                align: "start",
                sortable: false,
                value: "pluviometre.libelle",
                divider: true,
                width: '100px'
            },
            {
                text: "GPS Pluviometre",
                align: "start",
                sortable: false,
                value: "longititude",
                divider: true,
                width: '100px'
            },
            {
                text: "Hauteur (mm)",
                align: "start",
                sortable: false,
                value: "hauteur",
                divider: true,
                width: '5%'
            },

            {
                text: "Nbre jours",
                align: "start",
                sortable: false,
                value: "nombre_jours_pluie",
                divider: true,
                width: '5%'
            },
            {
                text: "Decate",
                align: "start",
                sortable: false,
                value: "decade",
                divider: true,
                width: '5%'
            },
            {
                text: "mois",
                align: "start",
                sortable: false,
                value: "mois",
                divider: true,
                width: '5%'
            },
            {
                text: "Trimestre",
                align: "start",
                sortable: false,
                value: "trimestre",
                divider: true,
                width: '5%'
            },
            {
                text: "Année",
                align: "start",
                sortable: false,
                value: "annee",
                divider: true,
                width: '5%'
            },
            {
                text: "Village",
                align: "start",
                sortable: false,
                value: "village.libelle",
                divider: true,
                width: '150px'
            },
            {
                text: "Arrondissement",
                align: "start",
                sortable: false,
                value: "village.arrondissement.libelle",
                divider: true,
                width: '150px'
            },
            {
                text: "Commune",
                align: "start",
                sortable: false,
                value: "village.arrondissement.commune.libelle",
                divider: true,
                width: '150px'
            },
            {
                text: "Direction",
                align: "start",
                sortable: false,
                value: "direction.libelle_ddaep",
                divider: true,
                width: '150px'
            },
            {
                text: "GPS de collecte",
                align: "start",
                sortable: false,
                value: "longititude",
                divider: true,
                width: '100px'
            },

        ],
        show1: true,

        editedItem: {
            id: '',
            observation: '',

        },

        list: [],
        liststatus: ['En attente', 'Valider', 'Rejeter'],
        listdirection: [],
        listdepartement: [],
        listcommune: [],
        listarrondissement: [],
        listvillage: [],

        listcommune2: [],
        listarrondissement2: [],
        listvillage2: [],

        listprofil: [],

        listannee: [],
        listmois: [],
        listmois2: [],
        listtrimestre: [],

        etat: false,
        selectdepartement: '',
        selectcommune: '',
        selectarrondissement: '',
        selectvillage: '',
        selectdirection: '',
        selectprofil: '',
        selectannee: '',
        selectmois: '',
        selecttrimestre: '',
        selectstatus: '',

    }),

    watch: {

    },

    created() {
        //this.readAll_geographie();
        //this.readAll_Survey();
        this.readAll();
        this.readAll_globale_localisation();
        // this.readAll_Export_Excel();
    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            fd.append('id_village', this.selectvillage);
            fd.append('annee', this.selectannee);
            fd.append('mois', this.selectmois);
            fd.append('trimestre', this.selecttrimestre);
            fd.append('id_direction', this.selectdirection);
            const data = await api.createUpdatedata('admin-backoffice/collecte-donnee-pluviometre', fd);
            this.list = data;

        },

        readAll_globale_localisation: async function () {
            const data = await api.readAll('admin-backoffice/globale-localisation');
            this.listdepartement = data.departement;
            this.listcommune2 = data.commune;
            this.listarrondissement2 = data.arrondissement;
            this.listvillage2 = data.village;
            this.listdirection = data.ddaep;

            let fd = new FormData();
            const data2 = await api.createUpdatedata('admin-backoffice/all-filtre', fd);
            this.listannee = data2.annee;
            this.listmois2 = data2.mois;
            this.listtrimestre = data2.trimestre;

        },

        recherche: async function () {

            let fd = new FormData();
            fd.append('id_village', this.selectvillage);
            fd.append('annee', this.selectannee);
            fd.append('mois', this.selectmois);
            fd.append('trimestre', this.selecttrimestre);
            fd.append('id_direction', this.selectdirection);

        },

        async changeStatus(item) {
            this.dialog = true;
            this.editedItem.id = item.id;
            this.editedItem.observation = item.observation;
            this.selectstatus = item.status==1?'Valider':item.status==2?'Rejeter':'En attente';
            
        },

        async save_status(){

            let fd = new FormData();
            fd.append('id', this.editedItem.id);
            fd.append('observation', this.editedItem.observation);
            fd.append('status', this.selectstatus=='Valider'?'1':this.selectstatus=='Rejeter'?'2':'0');
           const data=await api.createUpdatedata('admin-backoffice/statut-observation-collecte', fd);
            if (data.status == true) {
                this.showAlert(data.message, 'success')
                this.clearitem();
                this.dialog = false;

            } else if (data.status == false) {
                this.showAlert(data.message, 'error')
            }
            this.readAll();
            this.dialog = false;
            this.selectstatus='0';
        
        },

        deletItem: async function (item) {

            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    const data = await api.readAll('admin-backoffice/delete-ddaep/' + item.id);
                    if (data.succes.status == true) {
                        this.showAlert('Suppression effectuée avec succès', 'success')
                        this.clearitem();
                        this.dialog = false;

                    } else if (data.succes.status == false) {
                        this.showAlert('Suppression non effectuée', 'error')
                    }
                    this.readAll();

                }
            })

        },

        changeStatus(item) {
            this.editedItem.id = item.id;
            this.editedItem.observation = item.observations;
            this.dialog = true;

        },

        clearitem() {
            this.editedItem.nom = '';
            this.editedItem.prenom = '';
            this.editedItem.email = '';
            this.editedItem.password = '';
            this.selectdirection = '';
            this.selectprofil = '0';
            this.etat = false;
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        getitem(item) {
            this.etat = true;

            this.editedItem = Object.assign({}, item);
            this.dialog = true;
            this.selectdirection = item.id_directions;
        },

        fetchData() {
            this.readAll();
        },
        fuseSearchtrimestre: function () {
            this.listmois = [];
            for (var i = 0; i < this.listtrimestre.length; i++) {
                if (this.selecttrimestre == 'T1') {
                    for (var j = 0; j < this.listmois2.length; j++) {
                        if (j <= 2) {
                            this.listmois.push(this.listmois2[j]);
                        }
                    }
                } else if (this.selecttrimestre == 'T2') {
                    for (var j = 0; j < this.listmois2.length; j++) {
                        if (j >= 3 && j <= 5) {
                            this.listmois.push(this.listmois2[j]);
                        }
                    }
                } else if (this.selecttrimestre == 'T3') {
                    for (var j = 0; j < this.listmois2.length; j++) {
                        if (j >= 6 && j <= 8) {
                            this.listmois.push(this.listmois2[j]);
                        }
                    }
                } else if (this.selecttrimestre == 'T4') {
                    for (var j = 0; j < this.listmois2.length; j++) {
                        if (j >= 9 && j <= 11) {
                            this.listmois.push(this.listmois2[j]);
                        }
                    }
                }

            }

        },
        fuseSearchdepartement: function () {
            this.listcommune = this.listcommune2.filter(this.parcourre_commune);
            this.listarrondissement = [];
            this.listvillage = [];

        },
        fuseSearchcommune: function () {
            this.listarrondissement = this.listarrondissement2.filter(this.parcourre_arrondissement);
            this.listvillage = [];

        },

        fuseSearcharrondissement: function () {
            this.listvillage = this.listvillage2.filter(this.parcourre_village);
        },

        parcourre_type_direction: function (obj) {
            if (obj.type_direction == 'DDAEP') {
                return true;
            } else {
                return false;
            }
        },

        parcourre_commune: function (obj) {
            if (obj.id_departements == this.selectdepartement) {
                return true;
            } else {
                return false;
            }
        },
        parcourre_arrondissement: function (obj) {
            if (obj.id_communes == this.selectcommune) {
                return true;
            } else {
                return false;
            }
        },

        parcourre_village: function (obj) {
            if (obj.id_arrondissements == this.selectarrondissement) {
                return true;
            } else {
                return false;
            }
        },

    },
};
</script>
